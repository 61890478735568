<template>
  <v-container>
    <div class="ml-2"><PageTitle text="Reviewed Orders" /></div>
    <h4 style="margin-top: 20px; margin-left: 10px" class="blue-grey--text">
      All reviewed orders
    </h4>
    <div class="buttonText ml-1 container3" id="result">
      <div class=" d-flex flex-grow-1 flex-column">
        <v-text-field
          class="mt-3 grey--text text--darken-2 font-weight-medium"
          v-model="search"
          rounded
          prepend-inner-icon="search"
          flat
          filled
          dense
          placeholder="Search by branch name, order reference, invoice no. or trip number"
        />
      </div>
      <v-col cols="12" sm="3" md="3" xs="3" xl="3" lg="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              v-model="selectedDate"
              label="Select Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedDate"
            scrollable
            class="font font-weight-medium box-shadow-light"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: -20px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="orders"
              item-key="order"
              no-data-text="No Reviewed Orders."
              :server-items-length="total"
              :options.sync="options"
              :loading="loading"
              loading-text="Loading Orders... Please wait"
              class="elevation-1"
            >
              <template #item.branch="{item}">
                <span
                  class=" font font-weight-me
              "
                  >{{ item.branchName }}</span
                >
              </template>
              <template #item.netAmount="{item}">
                <v-chip label small color="primary">
                  <span class="font font-weight-medium">{{
                    item.netAmount | converter
                  }}</span>
                </v-chip>
              </template>
              <template #item.isOrderReviewed="{item}">
                <v-chip label small color="success">
                  <span class="font font-weight-medium">{{
                    item.isOrderReviewed ? "Reviewed" : "Not Reviewed"
                  }}</span>
                </v-chip>
              </template>
              <template #item.actions="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="mx-1"
                      link
                      :to="{
                        name: 'master.reviewedDetails',
                        params: {
                          id: item.id
                        }
                      }"
                      dark
                      v-on="on"
                    >
                      <v-icon color="blue-grey">visibility</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip></template
              >
            </v-data-table>
          </div>
        </template>
      </v-col></v-row
    >
    <v-dialog v-model="dialog" width="400px">
      <v-card ref="form">
        <v-card-title>
          <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
            >Create Order</span
          >
          <v-spacer></v-spacer>
          <v-progress-linear
            v-show="progress"
            class="mx-6"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3">
              <v-col>
                <v-row>
                  <v-select
                    item-text="name"
                    item-value="id"
                    :items="branches"
                    dense
                    outlined
                    label="Branch"
                    ref="branch"
                    class="mx-2 px-2 required"
                    v-model="branch"
                    :rules="[rules.requiredBranch]"
                  ></v-select>

                  <v-text-field
                    dense
                    outlined
                    label="Cheque Number"
                    ref="name"
                    class="mx-2 px-2 required"
                    v-model="chequeNumber"
                    :rules="[rules.requiredChec]"
                  >
                  </v-text-field>

                  <v-select
                    item-text="name"
                    item-value="id"
                    :items="products"
                    dense
                    outlined
                    label="Product"
                    ref="product"
                    class="mx-2 px-2 required"
                    v-model="product"
                    :rules="[rules.requiredProduct]"
                  >
                  </v-select>
                </v-row>
                <v-row>
                  <v-text-field
                    dense
                    outlined
                    label="Quantity"
                    ref="quantity"
                    class="mx-2 px-2 required"
                    v-model="quantity"
                    :rules="[rules.requiredQuant]"
                  >
                  </v-text-field>
                  <v-text-field
                    dense
                    outlined
                    label="Amount"
                    ref="amount"
                    class="mx-2 px-2 required"
                    v-model="chequeAmount"
                    :rules="[rules.requiredDes]"
                  >
                  </v-text-field>
                  <!--                  <v-col cols="12" sm="12" md="12" lg="12"-->
                  <!--                    ><v-select-->
                  <!--                      dense-->
                  <!--                      :items="regions"-->
                  <!--                      v-model="region"-->
                  <!--                      ref="region"-->
                  <!--                      label="Region"-->
                  <!--                      outlined-->
                  <!--                      :rules="[rules.requiredRegion]"-->
                  <!--                      class="mx-2 px-2 my-n4 required"-->
                  <!--                    ></v-select-->
                  <!--                  ></v-col>-->

                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Latitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lat"-->
                  <!--                    v-model="lat"-->
                  <!--                    :rules="[rules.requiredLat]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Longitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lng"-->
                  <!--                    v-model="lng"-->
                  <!--                    :rules="[rules.requiredLng]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                </v-row>
              </v-col>
              <v-row>
                <v-spacer />
                <v-btn
                  small
                  class=" px-1 mt-n5 mb-4 ml-10"
                  text
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  small
                  color="blue"
                  class="  px-3 mt-n5 mb-4 ml-5 mr-10 white--text"
                  @click.stop="add"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="text-center">
      <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
          <v-progress-linear
            v-show="progress"
            indeterminate
            color="cyan"
          ></v-progress-linear>
          <v-card-title class="blue-grey--text">
            <h5>
              Delete this order?
            </h5>
          </v-card-title>

          <v-card-text>
            <h4>{{ orderInfo.chequeId.number }}</h4>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="darken-1" text @click="dialogDelete = false">
              No
            </v-btn>

            <v-btn color="red darken-1" text @click="deleteOrder()">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialogUpdate" width="400px">
      <v-card ref="form">
        <v-card-title>
          <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
            >Update Order</span
          >
          <v-spacer></v-spacer>
          <v-progress-linear
            v-show="progress"
            class="mx-6"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3">
              <v-col>
                <v-row>
                  <v-select
                    :items="products"
                    item-value="id"
                    item-text="name"
                    dense
                    outlined
                    label="Product"
                    ref="itemProduct"
                    class="mx-5 px-2 required"
                    v-model="itemProduct"
                    :rules="[rules.requiredProduct]"
                  >
                  </v-select>
                  <v-text-field
                    dense
                    outlined
                    label="Cheque Number"
                    ref="itemChequeNumber"
                    class="mx-5 px-2 required"
                    v-model="itemChequeNumber"
                    :rules="[rules.requiredChec]"
                  >
                  </v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    dense
                    outlined
                    label="Cheque Amount"
                    ref="itemCheckAmount"
                    class="mx-5 px-2 required"
                    v-model="itemChequeAmount"
                    :rules="[rules.requiredAmount]"
                  >
                  </v-text-field>
                  <v-text-field
                    dense
                    outlined
                    label="Quantity"
                    ref="itemQuantity"
                    class="mx-5 px-2 required"
                    v-model="itemQuantity"
                    :rules="[rules.requiredQuant]"
                  >
                  </v-text-field>

                  <!--                  <v-col cols="12" sm="12" md="12" lg="12"-->
                  <!--                    >-->
                  <!--                  <v-select-->
                  <!--                      dense-->
                  <!--                      :items="regions"-->
                  <!--                      v-model="itemRegion"-->
                  <!--                      ref="region"-->
                  <!--                      label="Region"-->
                  <!--                      outlined-->
                  <!--                      :rules="[rules.requiredRegion]"-->
                  <!--                      class="mx-2 px-2 my-n4 required"-->
                  <!--                    ></v-select-->
                  <!--                  ></v-col>-->

                  <!--                                <v-text-field-->
                  <!--                                        dense-->
                  <!--                                        outlined-->
                  <!--                                        label="Phone"-->
                  <!--                                        class="mx-5 px-2 required"-->
                  <!--                                        ref="phone"-->
                  <!--                                        v-model="itemPhone"-->
                  <!--                                        :rules="[rules.min]"-->
                  <!--                                >-->
                  <!--                                </v-text-field>-->
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Latitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lat"-->
                  <!--                    v-model="itemLat"-->
                  <!--                    :rules="[rules.requiredLat]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Longitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lng"-->
                  <!--                    v-model="itemLng"-->
                  <!--                    :rules="[rules.requiredLng]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                </v-row>
                <v-row>
                  <v-select
                    :items="branches"
                    item-value="id"
                    item-text="name"
                    dense
                    outlined
                    label="Branch"
                    ref="itemBranch"
                    class="mx-5 px-2 required"
                    v-model="itemBranch"
                    :rules="[rules.requiredBranch]"
                  ></v-select>
                </v-row>
              </v-col>
              <v-row>
                <v-spacer />
                <v-btn
                  small
                  class=" px-1 mt-n5 mb-4 ml-10"
                  text
                  @click="dialogUpdate = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  small
                  class="blue  px-3 mt-n5 mb-4 ml-5 mr-10 white--text"
                  text
                  @click.stop="save"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>
S
<script>
import PageTitle from "../../components/PageTitle";
import * as axios from "axios";
import moment from "moment";
import { debounce } from "lodash";
export default {
  name: "Reviewed",
  components: { PageTitle },
  data() {
    return {
      progress: false,
      dialog: false,
      formHasErrors: false,
      showSnackBar: false,
      dialogUpdate: false,
      description: "",
      products: [],
      branches: [],
      phone: "",
      lng: "",
      lat: "",
      message: "",
      status: "",
      product: "",
      chequeNumber: "",
      chequeAmount: 0,
      branch: "",
      quantity: "",
      itemId: "",

      itemChequeNumber: "",
      itemChequeAmount: "",
      itemQuantity: "",
      itemProduct: "",
      itemBranch: "",
      orders: [],
      itemObjects: {
        chequeAmount: null,
        product: null,
        branch: null,
        chequeNumber: null,
        quantity: 0
      },
      date: new Date().toISOString().substr(0, 10),
      menu: false,

      dialogDelete: false,
      region: "",
      location: "",
      orderInfo: {
        chequeId: {
          number: ""
        }
      },
      rules: {
        min: value => value.length >= 10 || "Must be 10 digits",
        requiredChec: value =>
          !!value || value === null || "Cheque Number is required.",
        requiredAmount: value =>
          !!value || value === null || "Amount is Required.",
        requiredProduct: value =>
          !!value || value === null || "Product is Required.",
        requiredQuant: value =>
          !!value || value === null || "Quantity is Required.",
        requiredBranch: value =>
          !!value || value === null || "Branch is Required."
      },
      loading: true,
      total: 0,
      selectedDate: "",
      options: {
        page: 1,
        itemsPerPage: 5
      },
      search: ""
    };
  },
  watch: {
    options: {
      handler(value) {
        this.getOrders(
          value.page,
          value.itemsPerPage,
          this.selectedDate,
          this.search
        );
      },
      deep: true
    },

    selectedDate(value) {
      this.getOrders(1, this.options.itemsPerPage, value, this.search);
    },
    search: debounce(function(value) {
      this.getOrders(1, this.options.itemsPerPage, this.selectedDate, value);
    }, 800)
  },

  computed: {
    headers() {
      return [
        {
          text: "Date",
          align: "start",
          sortable: false,
          value: "date"
        },
        {
          text: "Order Ref.",
          align: "start",
          sortable: false,
          value: "orderReference"
        },
        { text: "Invoice #", value: "invoiceNumber", sortable: false },
        { text: "Net Amount", value: "netAmount", sortable: false },
        { text: "Branch", value: "branchName" },
        { text: "Trip #", value: "tripNumber" },

        { text: "Action", value: "actions" }
      ];
    }
  },
  filters: {
    format(value) {
      return moment(value)
        .subtract(0, "days")
        .calendar();
    },

    status(value) {
      return value ? "Done" : "Undone";
    },

    reviewed(value) {
      return value ? "Yes" : "No";
    },

    converter(value) {
      return "GHS " + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
  },

  created() {
    this.getOrders(
      this.options.page,
      this.options.itemsPerPage,
      this.selectedDate,
      this.search
    );
  },

  methods: {
    getOrders(page, itemsPerPage, selectedDate, searchTerm) {
      this.progress = true;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${
          process.env.VUE_APP_BASE
        }/orders/reviewed/requests?isPaginated=true&page=${page}&limit=${itemsPerPage}${
          selectedDate ? "&date=" + selectedDate : ""
        }${searchTerm ? "&searchTerm=" + searchTerm : ""}`
      })
        .then(response => {
          // console.log(response);
          if (response.status === 200) {
            this.progress = false;
            this.loading = false;
            this.orders = response.data.paginateObj.docs;
            this.options.page = response.data.paginateObj.page;
            this.options.itemsPerPage = parseInt(
              response.data.paginateObj.limit
            );
            this.total = response.data.paginateObj.total;
          }
        })
        .catch(err => {
          this.loading = false;
          this.progress = false;
          if (err.statusCode === 401) {
            this.$router.replace({ path: "/login" });
          }
        });
    }
  }
};
</script>

<style scoped>
.container3 {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}

.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
#result .v-btn {
  min-width: 65px;
  width: 40px;
}
/* removes grey hover effect */
#result .v-btn::before {
  background-color: transparent;
}

/* adds icon scale hover effect */
#result .v-btn i:hover {
  transform: scale(1.15);
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
